/*BbmTestObj*/
import {EbPaymentReportGENERATED} from '../_generated/eb-payment-report-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';
import {McDateUtils} from '../common/mc-date-utils';
import {CurrencyUtil} from '../common/currency-util';

export class EbPaymentReport extends EbPaymentReportGENERATED {

  public static ACTION_FIND_MATCH = 'FIND_MATCH';
  public static ACTION_MARK_AS_INVALID = 'MARK_AS_INVALID';
  public static CREDIT_INDICATOR_TYPE_CREDIT = 'CRDT';
  public static CREDIT_INDICATOR_TYPE_DEBIT = 'DBIT';
  public static FILTER_TYPE = 'cc.jobs.edit.type';
  public static FILTER_STATUS = 'cc.common.status';
  public static FILTER_DATE_RANGE = 'cc.transactions.date-range';
  public static FILTER_MATCHING_TYPE = 'cc.common.view.matching-type';

  extractedDetails = {
    amount: 0,
    description: '',
    debtorIban: '',
    debtorName: '',
    currencyStr: '',
    creditDebitIndicator: ''
  };
  currencyCode!: string;

  private _fldActions: McHtml | null = null;

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new EbPaymentReport();
  }

  incomingPaymentsActions() {
    if (!this._fldActions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      if (this.isStatusUNMATCHED()) {
        actSet.addActionB(`<i class="fas fa-bullseye mc-submit-color mc-cursor-pointer"></i> ${McGod.t('cc.incoming-payments.find-match')}`, EbPaymentReport.ACTION_FIND_MATCH, this, [McGod.PERM_MC_BILLING_MY_STATEMENT_VIEW], '', ActionOptions.OPTION_EDIT);
        actSet.addActionB(`<i class="fa fa-times mc-submit-color mc-cursor-pointer"></i> ${McGod.t('cc.incoming-payments.mark-as-invalid')}`, EbPaymentReport.ACTION_MARK_AS_INVALID, this, [McGod.PERM_MC_BILLING_MY_STATEMENT_VIEW], '', ActionOptions.OPTION_EDIT);
      }
      if (this.isStatusINVALID()) {
        actSet.addActionB(`<i class="fas fa-undo mc-submit-color mc-cursor-pointer"></i> ${McGod.t('cc.incoming-payments.undo-mark-as-invalid')}`, EbPaymentReport.ACTION_MARK_AS_INVALID, this, [McGod.PERM_MC_BILLING_MY_STATEMENT_VIEW], '', ActionOptions.OPTION_EDIT);
      }
      this._fldActions = html;
    }
    return this._fldActions;
  }

  getDebtorName(): string {
    return this.extractedDetails.debtorName;
  }

  getIban(): string {
    return this.extractedDetails.debtorIban;
  }

  getIndicator(): string {
    if (this.extractedDetails.creditDebitIndicator === EbPaymentReport.CREDIT_INDICATOR_TYPE_CREDIT) {
      return 'Credit';
    } else {
      return 'Debit';
    }
  }

  getDescription(): string {
    return `<div class="icon-tooltip">
                    <div>${this.extractedDetails.description.slice(0, 50)}...</div>
                    <span class="icon-tooltip-text">${this.extractedDetails.description}</span></div>`;
  }

  getAmount(): string {
    return CurrencyUtil.format(this.extractedDetails.amount, this.extractedDetails.currencyStr);
  }

  getTransactionDateTime(): string {
    return McDateUtils.newFormatDateTimeString(this.transactionDate);
  }

  getIconLabel(): string {
    if (this.isStatusMATCHED()) {
      return `<div class="">
                    <i class="fas fa-bullseye icon-blue"></i>
                    <span class="icon-tooltip-text text-capitalize">${this.status.toLowerCase()}</span>
              </div>`;
    }
    if (this.isStatusUNMATCHED()) {
      return `<div class="">
                    <i class="fas fa-bullseye icon-gray"></i>
                    <span class="icon-tooltip-text text-capitalize">${this.status.toLowerCase()}</span>
              </div>`;
    }
    if (this.isStatusINVALID()) {
      return `<div class="">
                    <i class="fa fa-times icon-gray"></i>
                    <span class="icon-tooltip-text text-capitalize">${this.status.toLowerCase()}</span>
              </div>`;
    }
    if (this.isStatusFAILED()) {
      return `<div class="">
                    <i class="fas fa-times-circle icon-red""></i>
                    <span class="icon-tooltip-text text-capitalize">${this.status.toLowerCase()}</span>
              </div>`;
    }
    return ''
  }

  public override populateFromJson(aSourceJson: any) {
    super.populateFromJson(aSourceJson);
    this.extractedDetails = aSourceJson.recordDetails;
  }

  getPaymentType() {
    return EbPaymentReport.getTypeLabel(this.type);
  }

  getMatchingType() {
    switch (this.matchingType) {
      case EbPaymentReport.MATCHING_TYPE_MANUAL:
        return 'Manually';
      case EbPaymentReport.MATCHING_TYPE_AUTO:
        return 'Auto';
      case EbPaymentReport.MATCHING_TYPE_SECONDARY:
        return 'Secondary';
      default:
        return null;
    }
  }
}
