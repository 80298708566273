import {Component, OnInit} from '@angular/core';
import {
  CsrContract,
  CsrContractList,
  McBoolean,
  McConsumer,
  McConsumersService,
  McCountry,
  McCurrency,
  McEntity2,
  McGod,
  WebFile,
  EbFactoringService,
  SortCriteriaList,
  CsrContractService, WebFileService
} from '@miticon-ui/mc-core';
import {ActivatedRoute, Router} from '@angular/router';
import { ConfirmationDialogComponent, MkFilterConfig, MkFilterOutput, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';
import {saveAs} from "file-saver";
import {MatDialog} from "@angular/material/dialog";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mc-consumer-consumer-360-contracts-part',
  templateUrl: './mc-consumer-consumer-360-contracts-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-contracts-part.component.scss']
})
export class McConsumerConsumer360ContractsPartComponent implements OnInit {

  csrContract!: CsrContract;
  webFile!: WebFile;
  csrContractList = new CsrContractList();
  showCreateContractMcb = new McBoolean();
  consumerId!: number;
  mcGod = McGod.getInstance();
  mcEntity = new McEntity2();
  mcCountry = new McCountry();
  currency = new McCurrency();
  mcConsumer!: McConsumer;
  matRoute: string;

  // table
  items = [];
  totalItemsCount = 0;
  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  mkMatMenuActionItems = [
    {
      title: McGod.t('cc.out-factoring.download'),
      matIcon: 'download',
      actionCd: 'Download',
      permission: McGod.PERM_MC_CONSUMER_VIEW
    },
    {
      title: McGod.t('cc.common.edit.cancel'),
      matIcon: 'cancel',
      actionCd: 'Cancel',
      permission: McGod.PERM_MC_CONSUMER_VIEW
    }
  ];

  constructor(private mcConsumersService: McConsumersService,
              private factoringService: EbFactoringService,
              private router: Router,
              private webFileService: WebFileService,
              private csrContractService: CsrContractService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private toastr: ToastrService,) {
  }

  ngOnInit() {
    this.consumerId = this.route.snapshot.params['id'];
    this.initTableConfig();

    this.actLoad(this.pageNumber, this.pageSize);
    this.currency = this.factoringService.activeChildFactoring.mcCurrency;
    this.matRoute = `/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/create-contract/${this.consumerId}`;
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.consumer.contract-id'), 'id', 250, 'id');
    this.tableConfig.addColumnStandard(McGod.t('cc.my-factoring.contract-name'), 'contractName', 250, 'contractName');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.contract-start-date'), 'fldStartDate()', 250, 'startDate');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.contract-end-date'), 'fldEndDate()', 250, 'endDate');
    this.tableConfig.addColumnStandard(McGod.t('cc.my-factoring.payment-method'), 'getPaymentMethod()', 250, 'paymentMethod');
    this.tableConfig.addColumnSvgIcon(McGod.t('cc.common.view.status'), 'getStatusCd()', 'statusCd');
  }

  actLoad(pageNumber: number, pageSize: number) {
    this.isLoading = true;

    const sortCriteria = new SortCriteriaList();
    sortCriteria.addSort('id', 'DESC');

    this.csrContractService.contractsByConsumer(this.consumerId, pageNumber, pageSize, sortCriteria)
      .subscribe((res) => {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => object = CsrContract.createFromJson(object));
      });
  }

  openCreateContractSteps() {
    this.showCreateContractMcb.setTrue();
  }

  onFilterChanged(filters: MkFilterOutput) {
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize);
  }

  onActionMkMatMenuItems($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case 'Download':
        this.webFileService.getById($event.item.idWebFile).subscribe((webFile: any) => {
          if(webFile) {
            this.webFileService.downloadFile($event.item.idWebFile, webFile.typeCd).subscribe((file:any) => {
              saveAs(file, webFile.originalFilename);
            })
          }
        })
        break;
      case 'Cancel':
        this.dialog.open(ConfirmationDialogComponent, {
          height: '250px',
          width: '600px',
          autoFocus: false,
          restoreFocus: false,
          data: {
            header: 'cc.consumer360.cancel-contract',
            description: 'cc.consumer360.cancel-contract-text',
            cancelBtnLabel: 'cc.common.edit.no',
            confirmBtnLabel: 'cc.common.edit.yes'
          }
        }).afterClosed().subscribe((data: boolean) => {
          if(data) {
            this.onCancelContactAction($event.item.id);
          }
        });
        break;
    }
  }

  onCancelContactAction(contract: number) {
    this.csrContractService.cancelContract(contract).subscribe((data) => {
      if(data) {
        this.toastr.success(McGod.t("cc.consumer360.successfully-cancelled"));
        this.actLoad(this.pageNumber, this.pageSize)
      }
    }, (err) => {
      this.toastr.error(err.error.message);
    });
  }

  onContractSaved($event: unknown) {
    this.actLoad(this.pageNumber, this.pageSize);
  }
}
