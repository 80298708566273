<ng-container *ngIf="matMenuItems" class="mk-mat-table-actions">
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-container *ngFor="let item of matMenuItems">
      <button class="action"
              *ngIf="mcGod.userHasPermission(item.permission)"
              mat-menu-item [disabled]="isDisabled(item)"
              [class.hidden]="isProcessMenuItemHidden(item) || isSepaExportMenuItemHidden(item)"
              (click)="matMenuItemClicked(item)">
        <mat-icon>{{item.matIcon}}</mat-icon>
        <span class="action_title">{{item.title}}</span>
      </button>
    </ng-container>
  </mat-menu>
</ng-container>
