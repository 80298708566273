import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BaseDirective,McGod, McProduct, McProductFilter, SortCriteriaList, McProductService,} from "@miticon-ui/mc-core";
import { MkTableConfig, MkFilterConfig, MkMatMenuItem, MkFilterOutput, MkMatTableMenuAction, ConfirmationDialogComponent } from '@miticon-ui/mc-components';
import {takeUntil} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import { MatDialog } from '@angular/material/dialog';
import { McProductAddCategoryComponent } from '../../components/mc-product-add-category/mc-product-add-category.component';
import { McProductAddEditProductComponent } from '../../components/mc-product-add-edit-product/mc-product-add-edit-product.component';
import {McProductProductProfilePartComponent} from '../../components/mc-product-product-profile-part/mc-product-product-profile-part.component';

@Component({
  selector: 'mc-product-product-list-page',
  templateUrl: './mc-product-product-list-page.component.html',
  encapsulation: ViewEncapsulation.None
})

export class McProductProductListPageComponent extends BaseDirective implements OnInit {
  mcProductFilter = new McProductFilter();
  totalItemsCount!: number;
  pageSize = 5;
  pageNumber= 0;
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  items: McProduct[] = [];
  searchTooltip = this.tS.instant('cc.products.search-tooltip');

  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.common.view.view'),
      actionCd: McProduct.ACTION_SHOW_PROFILE,
      matIcon: 'visibility',
      permission: McGod.PERM_PP_PRODUCT_VIEW
    },
    {
      title: this.tS.instant('cc.common.edit'),
      actionCd: McProduct.ACTION_SHOW_EDIT,
      matIcon: 'edit',
      permission: McGod.PERM_PP_PRODUCT_UPDATE,
    },
    {
      title: this.tS.instant('cc.common.delete'),
      actionCd: McProduct.ACTION_SHOW_DELETE,
      matIcon: 'delete',
      permission: McGod.PERM_PP_PRODUCT_UPDATE
    }
  ];

  constructor(private tS: TranslateService,
              private toastrService: ToastrService,
              private mcProductService: McProductService,
              private dialog: MatDialog){
    super();
  }

  ngOnInit() {
    this.initTableConfig();
    this.actLoad(this.pageNumber, this.pageSize, this.mcProductFilter);
  }

  initTableConfig(){
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.name'), 'name', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.product.view.unit-cost'), 'fldUnitPriceLoc()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.product.view.vat') + '(%)', 'fldVatRate()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('pp.product.charging-type'), 'getChargingType()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('pp.product.product-type'), 'getProductType()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.jobs.view.category'), 'fldCategory()', 250);
    this.tableConfig.addColumnSvgIcon(this.tS.instant('cc.common.status'), 'getStatusCd()');
  }

  actLoad(pageNumber: number, pageSize: number, mcProductFilter: McProductFilter): void {
    this.pageNumber=pageNumber;
    this.pageSize= pageSize;
    this.isLoading = true;
    const sortCriteriaList = new SortCriteriaList();

    this.mcProductService.getByFilter(mcProductFilter, this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if(res){
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => McProduct.createFromJson(object));
      }
    })
  }

  onFilterChanged(filterOutput: MkFilterOutput){
    this.mcProductFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.mcProductFilter);
  }

  onTableAction($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case McProduct.ACTION_SHOW_PROFILE:
        this.openViewProductDialog($event.item);
        break;
      case McProduct.ACTION_SHOW_EDIT:
        this.openEditProductDialog($event.item)
        break;
      case McProduct.ACTION_SHOW_DELETE:
        this.openDeleteProductDialog($event.item);
        break;
    }
  }

  openViewProductDialog(product: McProduct) : void{
    this.dialog.open(McProductProductProfilePartComponent, {
      height: '720px',
      width: '900px',
      autoFocus: false,
      restoreFocus: false,
      data: product
    });
  }

  openDeleteProductDialog(product: McProduct): void {
    this.dialog.open(ConfirmationDialogComponent,{
      height: '230px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'cc.product.delete-product',
        description: 'cc.product.are-you-sure-you-want-to-delete-product',
        cancelBtnLabel: 'cc.common.edit.cancel',
        confirmBtnLabel: 'cc.common.delete'
      }
    }).afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.onDeleteProductAction(product);
      }
    })
  }

  openEditProductDialog(product: any): void {
    this.dialog.open(McProductAddEditProductComponent,{
      height: '700px',
      width: '850px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        currentProduct: product
      }
    }).afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.actLoad(this.pageNumber, this.pageSize, this.mcProductFilter);
      }
    })
  }

  openCreateProductDialog() {
    this.dialog.open(McProductAddEditProductComponent,{
      height: '700px',
      width: '850px',
      autoFocus: false,
      restoreFocus: false,
      data: {}
    }).afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.actLoad(this.pageNumber, this.pageSize, this.mcProductFilter);
      }
    })
  }

  onDeleteProductAction(product: McProduct) {
      this.mcProductService.archive(product.id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response) => {
          if (response) {
            this.toastrService.success(this.tS.instant('cc.product.the-product-has-been-successfully-deleted'));
            this.actLoad(this.pageNumber, this.pageSize, this.mcProductFilter);
          }
        }, () => {
          this.toastrService.error(this.tS.instant('cc.product.error-occurred-the-product-has-not-been-deleted-please-try-again'));
        });
  }

  openCreateCategoryDialog(){
    this.dialog.open(McProductAddCategoryComponent,{
      height: '600px',
      width: '650px',
      panelClass: 'dialog',
      autoFocus: false,
      restoreFocus: false
    });
  }
}
